.container {
    padding-bottom: 12.5%;
}

.laptop {
    --width: 88%; // 1074px
    width: var(--width);
    height: 0;
    padding-bottom: calc(var(--width) * 0.638);
    margin: 0 auto;
    position: relative;

    * {
        position: absolute;
    }

    .lid {
        height: 100%;
        width: 100%;
        background: linear-gradient(45deg, rgb(var(--c-gray-10)), rgb(var(--c-gray-10)));
        border-radius: calc(var(--width) * 0.0214) / calc(var(--width) * 0.035);
        box-shadow: inset 2px -8px 12px rgba(var(--c-gray-300), 0.3);
        transform-origin: bottom;
    }

    .screen {
        top: calc(var(--width) * 0.035);
        left: calc(var(--width) * 0.03);
        right: calc(var(--width) * 0.03);
        bottom: calc(var(--width) * 0.046554935);
        background-color: white;
        border-radius: calc(var(--width) * 0.004692737) / calc(var(--width) * 0.007);
        box-shadow: -2px 2px 12px rgba(var(--c-gray-200), 0.2);
        overflow: hidden;
        transform-origin: bottom;
    }

    .chassis {
        width: 100%;
        height: calc(var(--width) * 0.279329609);
        top: calc(99%);

        background: linear-gradient(rgba(var(--c-gray-300), 0.2), rgba(var(--c-gray-300), 0)),
            linear-gradient(45deg, rgb(var(--c-gray-30)), rgb(var(--c-gray-20)));
        box-shadow: inset 0 -4px 6px rgba(var(--c-gray-300), 0.5), inset -1px -12px 8px rgba(255, 255, 255, 0.9);
        border-radius: calc(var(--width) * 0.02) / calc(var(--width) * 0.105);

        transform-origin: 50% 0;
        transform: perspective(58vw) rotateX(40deg);

        * {
            opacity: 0.3;
            background: linear-gradient(45deg, rgb(var(--c-gray-160)), rgb(var(--c-gray-150)));
            box-shadow: 2px 2px 2px hsla(0, 0%, 100%, 0.75);
        }
    }

    .keyboard {
        left: calc(var(--width) * 0.083798883);
        right: calc(var(--width) * 0.083798883);
        top: calc(var(--width) * 0.05);
        height: calc(var(--width) * 0.5);
        border-radius: calc(var(--width) * 0.008) / calc(var(--width) * 0.076);
    }

    .trackpad {
        left: calc(var(--width) * 0.327102804);
        right: calc(var(--width) * 0.327102804);
        top: calc(var(--width) * 0.57);
        height: calc(var(--width) * 0.4);
        border-radius: calc(var(--width) * 0.04) / calc(var(--width) * 0.2);
    }

    .shadow {
        left: calc(var(--width) * 0.1);
        right: calc(var(--width) * 0.1);
        top: calc(var(--width) * 0.5);
        height: 80%;
        opacity: 0.3;
        background: rgb(var(--c-gray-400));
        filter: blur(30px);
    }
}
